<template>
	<div class="detail-wrap">
		<div class="left"></div>
		<div class="right"></div>
		<el-breadcrumb separator="/" class="path">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				<i class="n el-icon-s-home"></i>
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/help' }">帮助列表</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">帮助详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="help-detail" v-loading="loading">
			<div class="title">{{ detail.title }}</div>
			<div class="info">
				<!-- <div class="time">{{ $util.timeStampTurnTime(detail.create_time) }}</div> -->
			</div>
			<div class="content" v-html="detail.content"></div>
		</div>
	</div>
</template>

<script>
import { helpDetail } from '@/api/cms/help';
export default {
	name: 'help_detail',
	components: {},
	data: () => {
		return {
			detail: [],
			loading: true
		};
	},
	created() {
		this.id = this.$route.path.replace('/help/item/', '');
		this.getDetail();
	},
	watch: {
		$route(curr) {
			this.id = curr.params.pathMatch;
			this.getDetail();
		}
	},
	methods: {
		getDetail() {
			helpDetail({
				id: this.id
			})
				.then(res => {
					if (res.code == 0) {
						if (res.data) {
							this.loading = false;
							this.detail = res.data;
						} else {
							this.$router.push({ path: '/help' });
						}
					}
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.detail-wrap{
	width: 1200px;
	margin: 0px auto;
	position: relative;	
	// margin-top: 2px;
	.path {
		width: 100%;
		box-shadow: 0 17px 20px -8px #EEE inset;
	}
	.left{
		position: absolute;
		top: 0;
		left: -30rem;
		box-shadow: 0 17px 20px -8px #EEE inset;
		width: 30rem;
		height: 30px;
		z-index: 99;
	}
	.right{
		position: absolute;
		top: 0;
		right: -20rem;
		height: 30px;
		width: 20rem;
		box-shadow: 0 17px 20px -8px #EEE inset;
	}
}
.help-detail {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
	.title {
		font-weight: 600;
		text-align: center;
		font-size: 26px;
		margin: 10px 0;
	}
	.info {
		margin: 0 43px;
		// border-bottom: 1px dotted #e9e9e9;
		.time {
			text-align: center;
			color: #838383;
			margin-bottom: 17px;
		}
	}
	.content {
		padding-top: 10px;
		margin: 0 65px;
	}
}
.path {
	padding: 15px 0;
}
</style>
